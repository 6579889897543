import { useState } from 'react'
import {
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  RadioGroup,
  RadioGroupCardItem,
  LabelWithValidation
} from './setup-form-components'
import { InstallationFileMeta, InstallationRecipe } from '@uv/machine'
import { Button } from '@uv/ui/components/ui/button'
import { CircleDashedIcon, CircleDotIcon, CircleIcon, CircleSlash, Loader2Icon } from 'lucide-react'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@ui/components/ui/alert-dialog'
import { DashboardCard } from '@/components/ui/card-dashboard'
import { InstallationDevFields } from './dev-fields'
import { UnitDistance, convertDistance, distanceUnits } from '@/lib/units'
import { cn } from '@ui/utils'
import { MachineStatus } from '@/components/dashboard/machine-status'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import {
  findRecipeForInstallation,
  hasRecipeForInstallation
} from '../../shared/find-recipe-for-installation'
import { useTranslation } from 'react-i18next'
import { translateDistanceUnitName } from '../../utils/translate-unit-name'
import { InstallationCustomOptions, InstallationSetupInput } from './1-setup'
import { SelectOrAdd } from './select-or-add'
import { format } from 'date-fns'
import { useCloudStartInstallation } from './use-cloud-start-installation'
import { RecipeMissingCard } from './recipe-missing-card'
import { TLINER_TYPE } from '@/lib/constants'

const tLinerAngleOptions = [
  { id: 45, name: '45°' },
  { id: 90, name: '90°' }
]

const extractNumberFromName = (name: string) => {
  return parseInt(name.match(/\d+/)?.[0] || '0')
}

type Props = {
  formInput: InstallationSetupInput
  updateFormInput: (
    updater:
      | ((prev: InstallationSetupInput) => Partial<InstallationSetupInput>)
      | Partial<InstallationSetupInput>
  ) => void
  customRecipe: InstallationRecipe | null
  customOptions: InstallationCustomOptions | null
}

export const InstallationSetupForm = ({
  formInput,
  updateFormInput,
  customRecipe,
  customOptions
}: Props) => {
  const { t } = useTranslation()
  const machineId = useDataStore(s => s.machineId)
  const isConnected = useDataStore(s => s.isConnected)
  const refreshData = useDataStore(s => s.refreshData)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)

  const {
    id,
    preparedInstallationId,
    installationType,
    name,
    installerId,
    installerName,
    projectId,
    projectName,
    linerType,
    linerId,
    linerName,
    tLinerMainId,
    tLinerMainName,
    tLinerTId,
    tLinerTName,
    tLinerAngle,
    linerBatchNumber,
    chemistryId,
    chemistryName,
    chemistryBatchNumber,
    distance,
    centered,
    opened
  } = formInput

  const installationOptions = useDataStoreLocalStorage(s => s.installationOptions)
  const installerOptions = Array.from(
    new Map(
      [
        ...(customOptions?.installer ? [customOptions.installer] : []),
        ...installationOptions.installers
      ].map(x => [x.id, x])
    ).values() // remove duplicates
  )

  const projectOptions = Array.from(
    new Map(
      [
        ...(customOptions?.project ? [customOptions.project] : []),
        ...installationOptions.projects
      ].map(x => [x.id, x])
    ).values() // remove duplicates
  )

  const linerOptions = Array.from(
    new Map(
      [...(customOptions?.liner ? [customOptions.liner] : []), ...installationOptions.liners].map(
        x => [x.id, x]
      )
    ).values() // remove duplicates
  ).sort((a, b) => {
    // Extract numbers from the name strings
    const numA = extractNumberFromName(a.name)
    const numB = extractNumberFromName(b.name)

    return numA - numB
  })

  const tLinerMainOptions = linerOptions.filter(l => l.type === TLINER_TYPE)
  // Only smaller or equal to the main liner
  const tLinerTOptions = tLinerMainOptions.filter(l => {
    if (!tLinerMainName) return true

    return extractNumberFromName(l.name) <= extractNumberFromName(tLinerMainName)
  })

  const linerTypeOptions = Array.from(
    new Map(
      [
        ...(customOptions?.linerType ? [customOptions.linerType] : []),
        ...installationOptions.linerTypes,
        {
          id: 'other',
          name: t('installation.setup.other-liner-option')
        }
      ]
        .filter(x => x.id !== TLINER_TYPE)
        .map(x => [x.id, x])
    ).values() // remove duplicates
  )

  const chemistryOptions = Array.from(
    new Map(
      [
        ...(customOptions?.chemistry ? [customOptions.chemistry] : []),
        ...installationOptions.chemistries,
        {
          id: 'other',
          name: t('installation.setup.other-chemistry-option')
        }
      ].map(x => [x.id, x])
    ).values() // remove duplicates
  )

  const recipeOptions = installationOptions.recipes

  const updateInstallationOptionsAfterInstallationStart = useDataStoreLocalStorage(
    s => s.updateInstallationOptionsAfterInstallationStart
  )
  const settings = useDataStoreLocalStorage(s => s.settings)
  const setSettingsPartially = useDataStoreLocalStorage(s => s.setSettingsPartially)

  const cloudStartInstallation = useCloudStartInstallation()
  const [isProcessingCloudInstallationStart, setIsProcessingCloudInstallationStart] =
    useState(false)

  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  const showMachineNotReadyDialog =
    (submitAttempted && refreshData?.ESTOP_OK === false) || (submitAttempted && !isConnected)
  const dismissMachineNotReadyDialog = () => setSubmitAttempted(false)

  const dateTime = format(new Date(), 'yyyy-MM-dd H:mm')
  const defaultInstallationName = projectName
    ? `${projectName} ${dateTime}`
    : machineId
      ? `${machineId} ${dateTime}`
      : dateTime

  const isOtherLiner = linerType === 'other'
  const isHouseLinerType = Boolean(linerType) && linerType !== 'other'
  const isHouseLiner = isHouseLinerType && Boolean(linerId)
  const isTLiner = installationType === 'TLINER'

  const isOtherChemistry = chemistryId === 'other'
  const isHouseChemistry = Boolean(chemistryId) && !isOtherChemistry
  const recipe =
    customRecipe ||
    findRecipeForInstallation({ linerId, chemistryId, centered, opened }, recipeOptions)

  const isRecipeMissing =
    !customRecipe &&
    !hasRecipeForInstallation({ linerId, chemistryId, centered, opened }, recipeOptions)
  const showRecipeMissing =
    isRecipeMissing && (isOtherLiner || isOtherChemistry || (isHouseLiner && isHouseChemistry))

  const onSubmit = async () => {
    setShowValidationErrors(true)

    ////////////////////////////////////
    // Form Validation
    ////////////////////////////////////

    // Liner
    const invalidLinerInstallationFields =
      !isTLiner &&
      (!linerType ||
        (!isOtherLiner && !linerId) ||
        (!isOtherChemistry && !chemistryId) ||
        (isHouseLiner && isHouseChemistry && opened === undefined) ||
        (isHouseLiner && isHouseChemistry && centered === undefined))

    // TLiner
    const invalidTLinerInstallationFields =
      isTLiner && (!tLinerMainId || !tLinerTId || !tLinerAngle)

    if (
      !installerId ||
      !distance ||
      invalidTLinerInstallationFields ||
      invalidLinerInstallationFields
    ) {
      return
    }

    const distanceNumber = Number.isNaN(Number(distance)) ? 0 : Number(distance)

    const distanceInMeters = convertDistance({
      value: distanceNumber,
      from: settings.unitDistance,
      to: 'm'
    })

    // Emergency stop button, check
    setSubmitAttempted(true)
    if (!machineId || !isConnected || !refreshData?.ESTOP_OK) return

    const fileMeta = {
      id,
      name: id, // This has to be the installation id, so we ensure uniqueness
      machineId,
      installationInfo: {
        id,
        preparedInstallationId,
        installationType: installationType,
        name: name || defaultInstallationName,
        installerId,
        installerName,
        machineId,
        projectId: projectId && projectName ? projectId : undefined,
        projectName: projectId && projectName ? projectName : undefined,
        startedAt: new Date().toISOString(),
        linerId: isTLiner ? undefined : linerId || undefined,
        linerName: isTLiner ? undefined : linerName || undefined,
        linerBatchNumber: linerBatchNumber || undefined,
        tLinerMainId: isTLiner ? tLinerMainId || undefined : undefined,
        tLinerMainName: isTLiner ? tLinerMainName || undefined : undefined,
        tLinerTId: isTLiner ? tLinerTId || undefined : undefined,
        tLinerTName: isTLiner ? tLinerTName || undefined : undefined,
        tLinerAngle: isTLiner ? Number(tLinerAngle) || undefined : undefined,
        chemistryId: chemistryId === 'other' ? undefined : chemistryId || undefined,
        chemistryName: chemistryName || undefined,
        chemistryBatchNumber: chemistryBatchNumber || undefined,
        distance: distanceInMeters,
        recipe
      }
    } satisfies InstallationFileMeta

    sendMachineAction({
      in: 'setup_filemeta',
      fileMeta
    })

    setIsProcessingCloudInstallationStart(true)
    await cloudStartInstallation(fileMeta.installationInfo)
    updateInstallationOptionsAfterInstallationStart(fileMeta.installationInfo)

    sendMachineAction({
      in: 'inversion_stage'
    })

    setIsProcessingCloudInstallationStart(false)
  }

  return (
    <section>
      <AlertDialog
        open={showMachineNotReadyDialog}
        onOpenChange={open => !open && dismissMachineNotReadyDialog()}
      >
        <AlertDialogContent className="max-h-1/2 p-12">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl mb-4">
              {!isConnected
                ? t('installation.setup.alert.title-not-conected')
                : t('installation.setup.alert.title-release-emergency-stop-button')}
            </AlertDialogTitle>
            <AlertDialogDescription className="text-lg leading-tight">
              {!isConnected
                ? t('installation.setup.alert.message-not-connected')
                : t('installation.setup.alert.message-release-emergency-stop-button')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="self-end gap-4 mt-16">
            <AlertDialogCancel className="w-full p-8 text-md font-bold rounded-xl">
              {t('installation.setup.alert.cancel-button')}
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <section className="grid grid-cols-[2fr,3fr,300px] gap-x-8 items-start w-full p-8 pt-4">
        <div className="col-start-1 col-span-1 flex flex-col gap-8">
          <DashboardCard className="flex flex-col gap-6">
            <SelectOrAdd
              label={t('installation.setup.installer-label')}
              showValidationError={
                showValidationErrors && (installerId === '' || installerName === '')
              }
              generateIdType="installer"
              valueId={installerId}
              value={installerName}
              updateForm={({ value, valueId }) =>
                updateFormInput({
                  installerId: valueId,
                  installerName: value
                })
              }
              onSelect={id => {
                const installerName = installerOptions.find(p => p.id === id)?.name ?? ''

                updateFormInput({
                  installerId: id,
                  installerName
                })
              }}
              selectOptions={installerOptions}
              emptyOptionText={t('installation.setup.installer-select-emtpy')}
              selectPlaceholder={t('installation.setup.installer-select-placeholder')}
              inputPlaceholder={t('installation.setup.installer-new-placeholder')}
            />

            <SelectOrAdd
              label={t('installation.setup.project-label')}
              showValidationError={false}
              generateIdType="project"
              valueId={projectId}
              value={projectName}
              updateForm={({ value, valueId }) =>
                updateFormInput({
                  projectId: valueId,
                  projectName: value
                })
              }
              onSelect={id => {
                const projectName = projectOptions.find(p => p.id === id)?.name ?? ''

                updateFormInput({
                  projectId: id,
                  projectName: projectName
                })
              }}
              selectOptions={projectOptions}
              emptyOptionText={t('installation.setup.project-select-emtpy')}
              selectPlaceholder={t('installation.setup.project-select-placeholder')}
              inputPlaceholder={t('installation.setup.project-new-placeholder')}
            />

            <div className="flex flex-col gap-2">
              <LabelWithValidation showValidationError={false}>
                {t('installation.setup.installation-name-label')}
              </LabelWithValidation>

              <Input
                value={name}
                onChange={e => updateFormInput({ name: e.target.value })}
                placeholder={defaultInstallationName}
              />
            </div>

            <div className="w-full flex flex-col gap-2">
              <LabelWithValidation
                showValidationError={showValidationErrors && distance.trim() === ''}
              >
                {t('installation.setup.installation-distance-label')}
              </LabelWithValidation>

              <div className="flex w-full gap-4">
                <Input
                  type="number"
                  min={0}
                  value={distance}
                  onChange={e =>
                    updateFormInput({
                      distance: e.target.value
                    })
                  }
                  placeholder={t('installation.setup.installation-distance-placeholder')}
                  className="w-1/2"
                />

                <div className="w-1/2 flex">
                  <Select
                    value={settings.unitDistance}
                    onValueChange={v =>
                      setSettingsPartially({
                        unitDistance: v as UnitDistance
                      })
                    }
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent>
                      {distanceUnits.map(({ id }) => {
                        return (
                          <SelectItem key={id} value={id}>
                            {translateDistanceUnitName(id)}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </DashboardCard>

          <InstallationDevFields recipe={recipe} />
        </div>

        <div className="col-start-2 col-span-1 flex flex-col gap-8">
          <DashboardCard className="flex flex-col gap-6">
            <div className="flex flex-col">
              <RadioGroup
                className="flex gap-4"
                value={installationType}
                onValueChange={v => {
                  updateFormInput(prev => {
                    if (v === prev.installationType) return prev
                    return {
                      installationType: v as InstallationSetupInput['installationType'],
                      linerType: '',
                      linerId: '',
                      linerName: '',
                      linerBatchNumber: '',
                      tLinerMainId: '',
                      tLinerMainName: '',
                      tLinerTId: '',
                      tLinerTName: '',
                      tLinerAngle: undefined
                    }
                  })
                }}
              >
                <RadioGroupCardItem value="LINER" id="liner" className="h-16 text-base">
                  <span>{t('installation.setup.installation-type-liner')}</span>
                </RadioGroupCardItem>

                <RadioGroupCardItem value="PATCH" id="patch" className="h-16 text-base">
                  <span>{t('installation.setup.installation-type-patch')}</span>
                </RadioGroupCardItem>

                <RadioGroupCardItem value="TLINER" id="tLiner" className="h-16 text-base">
                  <span>{t('installation.setup.installation-type-tliner')}</span>
                </RadioGroupCardItem>
              </RadioGroup>
            </div>

            {isTLiner ? (
              <div className="flex gap-4">
                <div className="w-1/3 flex flex-col gap-2">
                  <LabelWithValidation
                    showValidationError={showValidationErrors && tLinerMainId.trim() === ''}
                  >
                    {t('installation.setup.tliner-main-label')}
                  </LabelWithValidation>

                  <Select
                    onValueChange={value => {
                      updateFormInput(prev => ({
                        ...prev,
                        tLinerMainId: value,
                        tLinerMainName: linerOptions.find(l => l.id === value)?.name ?? '',
                        tLinerTId: '',
                        tLinerTName: ''
                      }))
                    }}
                    value={tLinerMainId}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t('installation.setup.tliner-main-select')} />
                    </SelectTrigger>
                    <SelectContent>
                      {tLinerMainOptions.length > 0 ? (
                        tLinerMainOptions.map(({ id, name }) => {
                          return (
                            <SelectItem key={id} value={id}>
                              {name}
                            </SelectItem>
                          )
                        })
                      ) : (
                        <SelectItem value="no-liners" disabled>
                          {t('installation.setup.no-liners')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>

                <div className="w-1/3 flex flex-col gap-2">
                  <LabelWithValidation
                    showValidationError={showValidationErrors && tLinerTId.trim() === ''}
                  >
                    {t('installation.setup.tliner-t-label')}
                  </LabelWithValidation>

                  <Select
                    onValueChange={value => {
                      updateFormInput(prev => ({
                        ...prev,
                        tLinerTId: value,
                        tLinerTName: linerOptions.find(l => l.id === value)?.name ?? ''
                      }))
                    }}
                    value={tLinerTId}
                  >
                    <SelectTrigger disabled={!tLinerMainId}>
                      <SelectValue placeholder={t('installation.setup.tliner-t-select')} />
                    </SelectTrigger>
                    <SelectContent>
                      {tLinerTOptions.length > 0 ? (
                        tLinerTOptions.map(({ id, name }) => {
                          return (
                            <SelectItem key={id} value={id}>
                              {name}
                            </SelectItem>
                          )
                        })
                      ) : (
                        <SelectItem value="no-liners" disabled>
                          {t('installation.setup.no-liners')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>

                <div className="w-1/4 flex flex-col gap-2">
                  <LabelWithValidation showValidationError={showValidationErrors && !tLinerAngle}>
                    {t('installation.setup.tliner-angle-label')}
                  </LabelWithValidation>

                  <Select
                    onValueChange={value => {
                      updateFormInput(prev => ({
                        ...prev,
                        tLinerAngle: Number(value)
                      }))
                    }}
                    value={tLinerAngle ? String(tLinerAngle) : ''}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t('installation.setup.tliner-angle-select')} />
                    </SelectTrigger>
                    <SelectContent>
                      {tLinerAngleOptions.length > 0 ? (
                        tLinerAngleOptions.map(({ id, name }) => {
                          return (
                            <SelectItem key={id} value={String(id)}>
                              {name}
                            </SelectItem>
                          )
                        })
                      ) : (
                        <SelectItem value="no-liners" disabled>
                          {t('installation.setup.no-angles')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            ) : (
              <>
                <div className="flex gap-4">
                  <div
                    className={cn('flex flex-col gap-2', isHouseLinerType ? 'w-[40%]' : 'w-full')}
                  >
                    <LabelWithValidation
                      showValidationError={showValidationErrors && linerType.trim() === ''}
                    >
                      {t('installation.setup.liner-type-label')}
                    </LabelWithValidation>

                    <Select
                      onValueChange={value => {
                        updateFormInput(prev => ({
                          ...prev,
                          linerType: value,
                          linerId: '',
                          linerName: '',
                          linerBatchNumber: value === 'other' ? '' : prev.linerBatchNumber
                        }))
                      }}
                      value={linerType}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t('installation.setup.liner-type-select')} />
                      </SelectTrigger>
                      <SelectContent>
                        {linerTypeOptions.length > 0 ? (
                          linerTypeOptions.map(({ id, name }) => {
                            return (
                              <SelectItem key={id} value={id}>
                                {name}
                              </SelectItem>
                            )
                          })
                        ) : (
                          <SelectItem value="no-liner-types" disabled>
                            {t('installation.setup.no-liners')}
                          </SelectItem>
                        )}
                      </SelectContent>
                    </Select>
                  </div>

                  {isHouseLinerType && (
                    <div className="w-[60%] flex flex-col gap-2">
                      <LabelWithValidation
                        showValidationError={showValidationErrors && linerId.trim() === ''}
                      >
                        {t('installation.setup.liner-label')}
                      </LabelWithValidation>

                      <Select
                        onValueChange={value => {
                          updateFormInput(prev => ({
                            ...prev,
                            linerId: value,
                            linerName: linerOptions.find(l => l.id === value)?.name ?? '',
                            linerBatchNumber: value === 'other' ? '' : prev.linerBatchNumber
                          }))
                        }}
                        value={linerId}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t('installation.setup.liner-select')} />
                        </SelectTrigger>
                        <SelectContent>
                          {linerOptions.filter(l => l.type === linerType).length > 0 ? (
                            linerOptions
                              .filter(l => l.type === linerType)
                              .map(({ id, name }) => {
                                return (
                                  <SelectItem key={id} value={id}>
                                    {name}
                                  </SelectItem>
                                )
                              })
                          ) : (
                            <SelectItem value="no-liners" disabled>
                              {t('installation.setup.no-liners')}
                            </SelectItem>
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                </div>

                {isOtherLiner && (
                  <div className="flex flex-col gap-2">
                    <LabelWithValidation>
                      {t('installation.setup.liner-name-label')}
                    </LabelWithValidation>

                    <Input
                      value={linerName}
                      onChange={e => updateFormInput({ linerName: e.target.value })}
                      placeholder={t('installation.setup.liner-name-placeholder')}
                    />
                  </div>
                )}
              </>
            )}

            {(isHouseLinerType || installationType === 'TLINER') && (
              <div className="flex flex-col gap-2">
                <LabelWithValidation>
                  {t('installation.setup.liner-batch-number-label')}
                </LabelWithValidation>

                <Input
                  value={linerBatchNumber}
                  onChange={e => updateFormInput({ linerBatchNumber: e.target.value })}
                  placeholder={t('installation.setup.liner-batch-number-placeholder')}
                />
              </div>
            )}

            <div className={'flex flex-col gap-2 mt-2 w-full'}>
              <LabelWithValidation
                showValidationError={showValidationErrors && chemistryId.trim() === ''}
              >
                {t('installation.setup.chemistry-label')}
              </LabelWithValidation>

              <Select
                onValueChange={value => {
                  updateFormInput(prev => ({
                    ...prev,
                    chemistryId:
                      value === 'other'
                        ? value
                        : (chemistryOptions.find(c => c.id === value)?.id ?? ''),
                    chemistryName:
                      value === 'other'
                        ? ''
                        : (chemistryOptions.find(c => c.id === value)?.name ?? ''),
                    chemistryBatchNumber: value === 'other' ? '' : prev.chemistryBatchNumber
                  }))
                }}
                value={chemistryId}
              >
                <SelectTrigger>
                  <SelectValue placeholder={t('installation.setup.chemistry-label')} />
                </SelectTrigger>
                <SelectContent>
                  {chemistryOptions.map(({ id, name }) => {
                    return (
                      <SelectItem key={id} value={id}>
                        {name}
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>
            </div>

            {isHouseChemistry && (
              <div className="flex flex-col gap-2">
                <LabelWithValidation>
                  {t('installation.setup.chemistry-batch-number-label')}
                </LabelWithValidation>

                <Input
                  value={chemistryBatchNumber}
                  onChange={e => updateFormInput({ chemistryBatchNumber: e.target.value })}
                  placeholder={t('installation.setup.chemistry-batch-number-placeholder')}
                />
              </div>
            )}

            {isOtherChemistry && (
              <div className="flex flex-col gap-2">
                <LabelWithValidation>
                  {t('installation.setup.chemistry-name-label')}
                </LabelWithValidation>
                <Input
                  value={chemistryName}
                  onChange={e => updateFormInput({ chemistryName: e.target.value })}
                  placeholder={t('installation.setup.chemistry-name-placeholder')}
                />
              </div>
            )}
          </DashboardCard>
        </div>

        <div className="col-start-3 flex flex-col gap-8 select-none">
          <MachineStatus strict />

          {isHouseLiner && isHouseChemistry && (
            <DashboardCard className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <LabelWithValidation
                  showValidationError={showValidationErrors && centered === undefined}
                >
                  {t('installation.setup.centering-label')}
                </LabelWithValidation>

                <RadioGroup
                  className="flex gap-4"
                  value={
                    centered === true ? 'centered' : centered === false ? 'not-centered' : undefined
                  }
                  onValueChange={v =>
                    updateFormInput({
                      centered: v === 'centered' ? true : v === 'not-centered' ? false : undefined
                    })
                  }
                >
                  <RadioGroupCardItem value="centered" id="centered">
                    <CircleDotIcon size={24} />
                    <span>{t('installation.setup.centering-center')}</span>
                  </RadioGroupCardItem>

                  <RadioGroupCardItem value="not-centered" id="not-centerd">
                    <CircleSlash size={24} />
                    <span>{t('installation.setup.centering-off')}</span>
                  </RadioGroupCardItem>
                </RadioGroup>
              </div>

              <div className="flex flex-col gap-2">
                <LabelWithValidation
                  showValidationError={showValidationErrors && opened === undefined}
                >
                  {t('installation.setup.ending-label')}
                </LabelWithValidation>

                <RadioGroup
                  className="flex gap-4"
                  value={opened === true ? 'open' : opened === false ? 'closed' : undefined}
                  onValueChange={v => {
                    updateFormInput({
                      opened: v === 'open' ? true : v === 'closed' ? false : undefined
                    })
                  }}
                >
                  <RadioGroupCardItem value="open" id="open">
                    <CircleDashedIcon size={24} />
                    <span>{t('installation.setup.ending-open')}</span>
                  </RadioGroupCardItem>

                  <RadioGroupCardItem value="closed" id="closed">
                    <CircleIcon size={24} />
                    <span>{t('installation.setup.ending-closed')}</span>
                  </RadioGroupCardItem>
                </RadioGroup>
              </div>
            </DashboardCard>
          )}

          {showRecipeMissing && <RecipeMissingCard isOther={isOtherLiner || isOtherChemistry} />}

          <Button
            onClick={onSubmit}
            className="w-full h-auto text-xl py-10 flex justify-center items-center gap-2 text-foreground bg-zinc-700 rounded-3xl hover:bg-zinc-700"
          >
            {isProcessingCloudInstallationStart && (
              <Loader2Icon className="mr-1 h-6 w-6 animate-spin" />
            )}
            <span>{t('installation.setup.start-installation-button')}</span>
          </Button>
        </div>
      </section>
    </section>
  )
}
