export const CONTROL_URL = import.meta.env.VITE_CONTROL_URL
if (!CONTROL_URL) throw new Error('CONTROL_URL is not defined')

// UI UNIT TRESHOLDS
export const MIN_AIR_TEMP_C = 0
export const MAX_AIR_TEMP_C = 50
export const MIN_AIR_PRESSURE_BAR = 0
export const MAX_AIR_PRESSURE_BAR = 1.2
export const MIN_SPEED_MH = 0
export const MAX_SPEED_MH = 110

// T LINER
export const TLINER_TYPE = 't-liner'
