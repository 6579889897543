import {
  InstallationStage,
  InstallationFileMeta,
  ActiveInstallationSamples,
  ActiveInstallation,
  LocalInstallationRecord,
  InstallationOptions,
  MachineSeen,
  InstallationInfo
} from '@uv/machine'
import { isBefore, subSeconds } from 'date-fns'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { UnitDistance, UnitPressure, UnitSpeed, UnitTemperature } from '../units'
import i18n from '@/lib/i18n/config'
import { createId } from '@paralleldrive/cuid2'

const ACTIVE_INSTALLATION_SAMPLE_RATE_SECONDS = 5

export type CustomWarning = {
  min: number | null
  max: number | null
  enabled: boolean
} | null

export type Settings = {
  flipUI: boolean
  unitDistance: UnitDistance
  unitPressure: UnitPressure
  unitSpeed: UnitSpeed
  unitTemperature: UnitTemperature
  customWarningUv: CustomWarning
  customWarningPressure: CustomWarning
}

export const locales = ['en', 'es', 'it'] as const
export type Locale = (typeof locales)[number]

const getDataStoreLocalStorageInitialState = () => {
  return {
    user: {
      email: null,
      deviceId: createId(),
      isAuthenticated: false
    },

    activeInstallationStage: null,
    activeInstallationFileMeta: null,
    activeInstallationFinishedAt: null,
    activeInstallationCustomRecipe: null,
    activeInstallationSamples: [],
    localInstallationRecords: [],
    locale: 'en' as const,
    settings: {
      flipUI: false,
      unitDistance: 'm' as const,
      unitPressure: 'bar' as const,
      unitSpeed: 'm/h' as const,
      unitTemperature: 'c' as const,
      customWarningUv: {
        min: null,
        max: null,
        enabled: false
      },
      customWarningPressure: {
        min: null,
        max: null,
        enabled: false
      }
    },
    installationOptions: {
      preparedInstallations: [],
      linerTypes: [],
      liners: [],
      chemistryTypes: [],
      chemistries: [],
      recipes: [],
      installers: [],
      projects: []
    },
    machinesSeen: [],
    cloudLastSync: null
  }
}
const initialState = getDataStoreLocalStorageInitialState()

type DataStoreLocalStorage = {
  user: {
    email: string | null
    deviceId: string
    isAuthenticated: boolean
  }
  setUser: ({ email, isAuthenticated }: { email: string | null; isAuthenticated: boolean }) => void

  // Active Installation
  setActiveInstallation: (installation: ActiveInstallation | null) => void
  activeInstallationStage: InstallationStage | null
  setActiveInstallationStage: (stage: InstallationStage | null) => void
  activeInstallationFileMeta: InstallationFileMeta | null
  setActiveInstallationFileMeta: (fileMeta: InstallationFileMeta | null) => void
  activeInstallationFinishedAt: string | null
  setActiveInstallationFinishedAt: (finishedAt: string | null) => void

  activeInstallationSamples: ActiveInstallationSamples[]
  addActiveInstallationSample: (sample: ActiveInstallationSamples) => void
  addActiveInstallationToLocalRecords: (finishedAt: string) => void
  clearActiveInstallationSamples: () => void

  // Local installations
  localInstallationRecords: LocalInstallationRecord[]
  addLocalInstallationRecord: (record: LocalInstallationRecord) => void

  // Settings and Config
  locale: Locale
  setLocale: (locale: Locale) => void
  settings: Settings
  // Similar API to setState, it accepts a function that exposes previous value
  setSettings: (v: Settings | ((prevSettings: Settings) => Settings)) => void
  // Accepts a partial value (using previous value as base)
  // And, similarly to setState API, it accepts a function that exposes previous value
  setSettingsPartially: (
    v: Partial<Settings> | ((prevSettings: Settings) => Partial<Settings>)
  ) => void
  installationOptions: InstallationOptions
  setInstallationOptions: (installationOptions: InstallationOptions) => void
  // TODO: This is not robust enough. There are cases when offline that polling when back online will overwrite the updated values
  // Fix it when we encounter the issue
  updateInstallationOptionsAfterInstallationStart: (installation: InstallationInfo) => void

  // Sync
  machinesSeen: MachineSeen[]
  updateMachineLastSeen: (machineId: string) => void
  updateMachineLastSync: (machineId: string) => void
  // seeMachine: (machine: MachineSeen) => void
  cloudLastSync: string | null
  setCloudLastSync: (lastSync: string) => void

  // Clear local state
  clearDataStoreLocalStorage: () => void
}

export const useDataStoreLocalStorage = create<DataStoreLocalStorage>()(
  devtools(
    persist(
      (set, get) => ({
        // AUTH
        user: initialState.user,
        setUser: ({
          email,
          isAuthenticated
        }: {
          email: string | null
          isAuthenticated: boolean
        }) => {
          set(state => ({
            user: {
              ...state.user,
              email,
              isAuthenticated
            }
          }))
        },

        // login: async (email: string, password: string) => {
        //   const { user: auth } = get()
        //   try {
        //     await login(email, password, auth.deviceId)
        //     set(state => ({
        //       user: {
        //         ...state.user,
        //         email,
        //         isAuthenticated: true
        //       }
        //     }))
        //   } catch (error) {
        //     console.error('Login failed:', error)
        //     throw error
        //   }
        // },
        //
        // logout: async () => {
        //   try {
        //     // const { auth } = get()
        //     // if (auth.accessToken) {
        //     //   await logout(auth.accessToken)
        //     // }
        //     set(state => ({
        //       user: {
        //         ...initialState.user,
        //         deviceId: state.user.deviceId // Preserve the device ID
        //       }
        //     }))
        //   } catch (error) {
        //     console.error('Logout failed:', error)
        //     throw error
        //   }
        // },

        setActiveInstallation: installation => {
          set({
            activeInstallationStage: installation ? installation.stage : null,
            activeInstallationFileMeta: installation ? installation.fileMeta : null,
            activeInstallationFinishedAt: installation ? installation.finishedAt : null,
            activeInstallationSamples: installation ? installation.samples : []
          })
        },
        activeInstallationStage: initialState.activeInstallationStage,
        setActiveInstallationStage: stage => set({ activeInstallationStage: stage }),
        activeInstallationFileMeta: initialState.activeInstallationFileMeta,
        setActiveInstallationFileMeta: fileMeta => set({ activeInstallationFileMeta: fileMeta }),
        activeInstallationFinishedAt: initialState.activeInstallationFinishedAt,
        setActiveInstallationFinishedAt: finishedAt =>
          set({ activeInstallationFinishedAt: finishedAt }),

        activeInstallationSamples: initialState.activeInstallationSamples,
        addActiveInstallationSample: sample => {
          const prevActiveInstallationSamples = get().activeInstallationSamples
          const lastTimestamp = prevActiveInstallationSamples?.at(-1)?.timestamp
          const isBeforeLastTimestamp =
            lastTimestamp !== undefined &&
            isBefore(
              new Date(lastTimestamp),
              subSeconds(new Date(sample.timestamp), ACTIVE_INSTALLATION_SAMPLE_RATE_SECONDS)
            )
          const shouldUpdate = prevActiveInstallationSamples.length === 0 || isBeforeLastTimestamp
          if (!shouldUpdate) return

          set(state => ({
            activeInstallationSamples: state.activeInstallationSamples
              ? [...state.activeInstallationSamples, sample]
              : [sample]
          }))
        },

        addActiveInstallationToLocalRecords: finishedAt => {
          const installationInfo = get().activeInstallationFileMeta?.installationInfo

          if (installationInfo) {
            get().addLocalInstallationRecord({
              ...installationInfo,
              finishedAt
            })
          }
        },
        clearActiveInstallationSamples: () => set({ activeInstallationSamples: [] }),

        // Local installations
        localInstallationRecords: initialState.localInstallationRecords,
        addLocalInstallationRecord: (record: LocalInstallationRecord) => {
          set(state => ({ localInstallationRecords: [...state.localInstallationRecords, record] }))
        },

        // Settings and Config
        locale: initialState.locale,
        setLocale: (locale: Locale) => {
          set({ locale })
          i18n.changeLanguage(locale)
        },

        settings: initialState.settings,
        setSettings: v => {
          set(prev => {
            const newSettings = typeof v === 'function' ? v(prev.settings) : v
            return {
              settings: newSettings
            }
          })
        },
        setSettingsPartially: v => {
          set(prev => {
            const prevSettings = prev.settings
            const nextValues = typeof v === 'function' ? v(prev.settings) : v

            return {
              settings: {
                ...prevSettings,
                ...nextValues
              }
            }
          })
        },
        installationOptions: initialState.installationOptions,
        updateInstallationOptionsAfterInstallationStart: installation =>
          set(s => {
            const isNewInstaller = !s.installationOptions.installers.some(
              i => i.id === installation.installerId && i.name === installation.installerName
            )
            const isNewProject = !s.installationOptions.projects.some(
              p => p.id === installation.projectId && p.name === installation.projectName
            )
            const isPreparedInstallation = s.installationOptions.preparedInstallations.some(
              i => i.id === installation.id
            )

            return {
              ...s,
              installationOptions: {
                ...s.installationOptions,
                installers: isNewInstaller
                  ? [
                      ...s.installationOptions.installers,
                      { id: installation.installerId, name: installation.installerName }
                    ]
                  : s.installationOptions.installers,
                projects:
                  isNewProject && installation.projectId && installation.projectName
                    ? [
                        ...s.installationOptions.projects,
                        { id: installation.projectId, name: installation.projectName }
                      ]
                    : s.installationOptions.projects,
                preparedInstallations:
                  isPreparedInstallation && installation.preparedInstallationId
                    ? s.installationOptions.preparedInstallations.filter(
                        i => i.id !== installation.preparedInstallationId
                      )
                    : s.installationOptions.preparedInstallations
              }
            }
          }),
        setInstallationOptions: installationOptions => set({ installationOptions }),

        // Sync
        machinesSeen: initialState.machinesSeen,
        updateMachineLastSeen: (machineId: string) => {
          set(state => {
            const index = state.machinesSeen.findIndex(
              existingItem => existingItem.id === machineId
            )
            const lastSeen = new Date().toISOString()
            const updatedMachinesSeen =
              index !== -1
                ? state.machinesSeen.map((item, i) =>
                    i === index
                      ? {
                          ...item,
                          lastSeen
                        }
                      : item
                  )
                : [
                    ...state.machinesSeen,
                    {
                      id: machineId,
                      lastSeen,
                      lastSync: null
                    }
                  ]

            return {
              machinesSeen: updatedMachinesSeen
            }
          })
        },
        updateMachineLastSync: (machineId: string) => {
          set(state => {
            const index = state.machinesSeen.findIndex(
              existingItem => existingItem.id === machineId
            )
            const now = new Date().toISOString()
            const updatedMachinesSeen =
              index !== -1
                ? state.machinesSeen.map((item, i) =>
                    i === index
                      ? {
                          ...item,
                          lastSeen: now,
                          lastSync: now
                        }
                      : item
                  )
                : [
                    ...state.machinesSeen,
                    {
                      id: machineId,
                      lastSeen: now,
                      lastSync: now
                    }
                  ]

            return {
              machinesSeen: updatedMachinesSeen
            }
          })
        },

        cloudLastSync: initialState.cloudLastSync,
        setCloudLastSync: (lastSync: string) => set({ cloudLastSync: lastSync }),

        // Clear local state (including auth)
        clearDataStoreLocalStorage: () => {
          set({
            ...getDataStoreLocalStorageInitialState()
          })
          i18n.changeLanguage('en')
        }
      }),
      { name: 'uv-local' }
    )
  )
)
